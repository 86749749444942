import { useState } from "react";
import type { FilterGroup } from "../../types/types";
import { SecondaryButtonMedium, TextButton } from "../Buttons/Buttons";
import { useTranslation } from "react-i18next";

type FilterItem = FilterGroup & { name: string };

export const SearchFilterTag = ({
  filter_items,
  on_filter_item_click,
}: {
  filter_items: FilterItem[];
  on_filter_item_click: (item: FilterItem) => void;
}) => {
  const [show_more, set_show_more] = useState(false);
  const { t } = useTranslation();

  const items_to_show =
    filter_items.length > 12 && !show_more
      ? filter_items.slice(0, 7)
      : filter_items;
  const remaining_items = filter_items.length - items_to_show.length;
  return (
    <>
      {items_to_show.map((item) => (
        <SecondaryButtonMedium
          key={item.id}
          onClick={() => on_filter_item_click(item)}
        >
          {item.name}
        </SecondaryButtonMedium>
      ))}
      {!!remaining_items && (
        <TextButton
          onClick={() => set_show_more(true)}
        >{`+ ${remaining_items} ${t("more")}`}</TextButton>
      )}
    </>
  );
};
