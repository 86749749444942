import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Flex,
  Flex1,
  Flex2,
  Form,
  RadioButtonContainer,
  SubmitButtonContainer,
} from "../../../../../layout/FormLayout";
import { Controller } from "react-hook-form";
import { TextField } from "../../../../../components/TextFields/TextFields";
import {
  CancelButton,
  PrimaryButtonMedium,
} from "../../../../../components/Buttons/Buttons";
import { H4Normal } from "../../../../../components/Typography/Typography";
import { SelectBoxV2 } from "../../../../../components/SelectBoxV2/SelectBoxV2";
import { yupResolver } from "@hookform/resolvers/yup";
import Axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { strings } from "../../../../../util/strings";
import type {
  AgilisUsersPaginatedOutput,
  DataMutate,
  IAddress,
  OptionType,
  Tenant,
} from "../../../../../types/types";
import { useValidateEmail } from "../../../../../util/useValidateEmail";
import {
  AddressFormSchema,
  POCFormSchema,
  POCManualFormSchema,
  convertUserToOption,
  getAddressUserOption,
  makeUrlWithParams,
  useFormWrapper,
  useStoreState,
} from "../../../../../util/util";
import {
  getCountryCode,
  getPhoneCodeOption,
  getPhoneCodesOptions,
  getPhoneNumber,
} from "../../../../../util/phone";
import {
  getAddressOneInputLabel,
  getAddressTwoInputLabel,
  getCountryOption,
  getStateOption,
  getStatesInputLabel,
  getZipCodeInputLabel,
} from "../../../../../util/location";
import { useCountriesList, useStatesList } from "../../../../../util/Locations";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import type { IAddressDetails } from "./EditLocations";
import { Modal } from "../../../../../components/Modal/Modal";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { ToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import useSWR from "swr";
import { endpoints } from "../../../../../endpoints";
import { useDebounce } from "../../../../../util/hooks";
import { StringParam, useQueryParams } from "use-query-params";
import * as yup from "yup";

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 30px;
`;

interface IEditAddressForm {
  point_of_contact_id: OptionType;
  contact_first_name: string;
  contact_last_name: string;
  email_address: string;
  phone_number: number;
  country_code: OptionType;
  company_name: string;
  tax_id: string;
  address1: string;
  address2: string;
  country: OptionType;
  county: string;
  state: OptionType;
  city: string;
  postal_code: string;
  same_as_hq: boolean;
}

interface EditAddressFormProps {
  address: IAddress;
  setEdit?: (arg: boolean) => void;
  tenantId: string;
  mutateTenant?: DataMutate<Tenant>;
  onSubmit?: (formInputs: IEditAddressForm) => void;
  title?: string;
  submit_form_inputs?: {
    email_source: string;
    success_msg: string;
  };
  closeForm?: () => void;
  hqAddress?: IAddress; // Add hqAddress prop
}

export const EditAddressForm = ({
  address,
  setEdit,
  tenantId,
  mutateTenant,
  closeForm,
  onSubmit,
  title,
  submit_form_inputs,
  hqAddress, // Destructure hqAddress prop
}: EditAddressFormProps) => {
  const { notifySuccess, notifyError } = useContext(Notifications);
  const [submitting, setSubmitting] = useState(false);
  const [pointOfContactType, setPointOfContactType] =
    useState<string>("manual");
  const [query, setQuery] = useQueryParams({
    q: StringParam,
  });
  const [userSearchQuery, setUserSearchQuery] = useState(query.q || "");
  const [debouncedSearchQuery] = useDebounce(userSearchQuery, 1000);
  const { validateEmail } = useValidateEmail();
  const [addressDetails, setAddressDetails] = useState<IAddressDetails>();
  const [formInputs, setFormInputs] = useState<IEditAddressForm>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [storedCountry, setStoredCountry] = useState(
    address.country === "USA" ? "US" : address.country
  );
  const [is_default_state_set, set_is_default_state_set] = useState(false);
  const countries = useCountriesList();
  const { t } = useTranslation();
  const [useHqAddress, setUseHqAddress] = useState(address.same_as_hq || false); // Toggle state

  const {
    handleSubmit,
    register,
    formState,
    control,
    errors,
    watch,
    setValue,
    reset,
  } = useFormWrapper({
    shouldUnregister: false,
    resolver: yupResolver(
      yup.lazy((formValues: any) =>
        yup.object().shape({
          ...(pointOfContactType === "manual"
            ? POCManualFormSchema(t, formValues)
            : {}),
          ...(pointOfContactType === "existing" ? POCFormSchema(t) : {}),
          ...AddressFormSchema(t),
        })
      )
    ),
    defaultValues: {
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      company_name: address.company_name,
      tax_id: address.tax_id,
      contact_first_name: address.contact_first_name,
      contact_last_name: address.contact_last_name,
      country: getCountryOption(countries, address.country),
      county: address.county ?? "",
      state: { label: null, value: address.state },
      email_address: address.email_address,
      phone_number: getPhoneNumber(address.phone_number),
      country_code: getPhoneCodeOption(getCountryCode(address.phone_number)),
      postal_code: address.postal_code,
      point_of_contact_id: getAddressUserOption(address),
      same_as_hq: address.same_as_hq ?? false, // Set the default value
    },
  });

  const { storefront_id } = useStoreState();
  const selectedCountry = watch("country");
  const states = useStatesList(selectedCountry?.value);

  // Wrapping handleToggle in useCallback
  const handleToggle = useCallback(
    (address: IAddress, isHqAddress: boolean) => {
      if (isHqAddress && address) {
        // Update form fields with HQ address when toggle is ON
        setValue("company_name", address.company_name);
        setValue("address1", address.address1);
        setValue("address2", address.address2);
        setValue("city", address.city);
        setValue("county", address.county);
        setValue("postal_code", address.postal_code);
        setValue("state", getStateOption(states, address.state));
        setValue("country", getCountryOption(countries, address.country));

        // Update POC details with HQ POC details
        setValue("contact_first_name", address.contact_first_name);
        setValue("contact_last_name", address.contact_last_name);
        setValue("email_address", address.email_address);
        setValue("phone_number", getPhoneNumber(address.phone_number));
        setValue("country_code", getPhoneCodeOption(getCountryCode(address.phone_number)));
        setValue("same_as_hq", true);
      } else {
        // Reset form fields when toggle is OFF
        setValue("company_name", "");
        setValue("address1", "");
        setValue("address2", "");
        setValue("city", "");
        setValue("county", "");
        setValue("postal_code", "");
        setValue("state", { label: "", value: "" });
        setValue("country", { label: "", value: "" });

        // Reset POC details to the existing address POC details
        setValue("contact_first_name", "");
        setValue("contact_last_name", "");
        setValue("email_address", "");
        setValue("phone_number", "");
        setValue("country_code", { label: "", value: "" });
        setValue("same_as_hq", false);
      }
    },
    [countries, states, setValue]
  );

  useEffect(() => {
    if (useHqAddress) {
      handleToggle(hqAddress || address, useHqAddress);
    }
  }, [useHqAddress, hqAddress, address, handleToggle]);

  const onSubmitForm = (inputs: IEditAddressForm) => {
    if (onSubmit) {
      onSubmit({ ...inputs, same_as_hq: useHqAddress });
    } else {
      onDefaultSubmit(inputs);
    }
  };

  const onDefaultSubmit = (inputs: IEditAddressForm) => {
    if (addressDetails?.has_transaction) {
      setFormInputs(inputs);
      setShowConfirmationModal(true);
    } else {
      submitForm(inputs);
    }
  };

  const submitForm = async (inputs: IEditAddressForm) => {
    setSubmitting(true);
    if (pointOfContactType === "manual") {
      const validEmail = await validateEmail({
        email: inputs.email_address,
        source:
          submit_form_inputs?.email_source ??
          "manage company info billing and subscription edit",
      });
      if (validEmail) {
        const newAddress = {
          ...inputs,
          same_as_hq: useHqAddress,  // Include the same_as_hq value
          phone_number: `${inputs.country_code.value}${inputs.phone_number}`,
          state: inputs.state.value,
          country: inputs.country.value,
          point_of_contact_id: undefined
        };
        try {
          await Axios.patch(
            `/v1/tenants/${tenantId}/addresses/${address.id}`,
            newAddress
          );
          notifySuccess(
            submit_form_inputs?.success_msg ??
              t("Address has been updated successfully")
          );
          setSubmitting(false);
          setEdit && setEdit(false);
          if (closeForm) {
            closeForm();
          }
          if (mutateTenant) {
            await mutateTenant();
          }
        } catch (error) {
          setSubmitting(false);
          notifyError(strings(t).submitError, { error });
        }
      }
    } else {
      const newAddress = {
        ...inputs,
        same_as_hq: inputs.same_as_hq,
        point_of_contact_id: inputs.point_of_contact_id.value,
        state: inputs.state.value,
        country: inputs.country.value,
        contact_first_name: undefined,
        contact_last_name: undefined,
        email_address: undefined,
        phone_number: undefined,
        country_code: { label: "", value: "" },
      };
      try {
        await Axios.patch(
          `/v1/tenants/${tenantId}/addresses/${address.id}`,
          newAddress
        );
        notifySuccess(t("Address has been updated successfully"));
        setSubmitting(false);
        setEdit && setEdit(false);
        if (closeForm) {
          closeForm();
        }
        if (mutateTenant) {
          await mutateTenant();
        }
      } catch (error) {
        setSubmitting(false);
        notifyError(strings(t).submitError, { error });
      }
    }
  };

  const changePointOfContact = (e: React.FormEvent<HTMLSelectElement>) => {
    if (e.currentTarget.value) {
      setPointOfContactType(e.currentTarget.value);
    }
  };

  const handleUserSearch = (value: string) => {
    if (value) {
      setUserSearchQuery(value);
    } else {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    setUserSearchQuery("");
    setQuery({ q: undefined });
  };

  const usersQuery =
    storefront_id && tenantId
      ? makeUrlWithParams(
          endpoints.v1_storefronts_id_tenants_id_users(storefront_id, tenantId),
          {
            offset: 0,
            limit: 100,
            order_by: "asc",
            q: debouncedSearchQuery || null,
          }
        )
      : null;

  const { data: usersData } = useSWR<AgilisUsersPaginatedOutput>(usersQuery);

  const users = usersData?.data.filter((user) => user.is_active);
  const userOptions = users?.map(convertUserToOption) ?? [];

  const cancelEditAddress = () => {
    reset();
    setShowConfirmationModal(false);
  };

  const proceedAddressChanges = () => {
    if (formInputs) {
      setShowConfirmationModal(false);
      submitForm(formInputs);
    }
  };

  useEffect(() => {
    const getAddressDetails = async () => {
      try {
        const addressDetails = await Axios.get<IAddressDetails>(
          `/v1/tenants/${tenantId}/addresses/${address.id}/details`
        );
        setAddressDetails(addressDetails.data);
      } catch (error) {
        notifyError(t("An error occurred"), { error });
        setEdit && setEdit(false);
      }
    };
    getAddressDetails();
  }, [address.id, notifyError, setEdit, storefront_id, t, tenantId]);

  useEffect(() => {
    if (countries.length > 0) {
      setValue("country", getCountryOption(countries, address.country));
    }
  }, [countries, address, setValue]);

  useEffect(() => {
    if (states.length > 0 && !is_default_state_set) {
      address?.state &&
        setValue("state", getStateOption(states, address.state));
      set_is_default_state_set(true);
    }
  }, [address.state, is_default_state_set, setValue, states]);

  useEffect(() => {
    if (selectedCountry?.value && selectedCountry?.value !== storedCountry) {
      setStoredCountry(selectedCountry?.value);
      setValue("address1", "");
      setValue("address2", "");
      setValue("city", "");
      setValue("county", "");
      setValue("postal_code", "");
      setValue("state", { label: "", value: "" });
    }
  }, [selectedCountry, storedCountry, setValue]);

  useEffect(() => {
    if (address.point_of_contact_id) setPointOfContactType("existing");
  }, [address]);

  useEffect(() => {
    if (pointOfContactType === "manual" && address.point_of_contact_id) {
      setValue("contact_first_name", "");
      setValue("contact_last_name", "");
      setValue("email_address", "");
      setValue("phone_number", "");
      setValue("country_code", { label: "", value: "" });
    }
  }, [pointOfContactType, address, setValue]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmitForm)}
        style={{ width: "65%" }}
      >
        <H4Normal>{title ?? t("Address")}</H4Normal>
        <TextField
          name="company_name"
          label={t("Company Name")}
          theref={register({
            required: true,
          })}
          formState={formState}
          readOnly={useHqAddress}
          errors={errors}
          type="text"
        />
        <TextField
          name="tax_id"
          label={t("Tax ID")}
          theref={register({
            required: false,
          })}
          formState={formState}
          errors={errors}
          type="text"
        />
        <Controller
          as={SelectBoxV2}
          control={control}
          name="country"
          autoComplete={"country-name"}
          placeholder={t("Country")}
          options={countries}
          rules={{
            required: true,
          }}
          errors={errors}
          formState={formState}
          disabled={useHqAddress}
        />
        {selectedCountry && states && (
          <>
            <TextField
              name="address1"
              autoComplete="address-line1"
              label={getAddressOneInputLabel(selectedCountry.value, t)}
              theref={register({
                required: true,
              })}
              formState={formState}
              readOnly={useHqAddress}
              errors={errors}
              type="text"
            />
            <TextField
              name="address2"
              autoComplete="address-line2"
              label={getAddressTwoInputLabel(selectedCountry.value, t)}
              theref={register({
                required: false,
              })}
              formState={formState}
              readOnly={useHqAddress}
              errors={errors}
              type="text"
            />
            <Flex>
              <Flex2
                style={{
                  marginRight: selectedCountry.value === "IN" ? "14px" : "0",
                }}
              >
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="state"
                  autoComplete="address-level1"
                  placeholder={getStatesInputLabel(selectedCountry.value, t)}
                  id="portSelectBox"
                  options={states}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                  disabled={useHqAddress}
                />
              </Flex2>
              {selectedCountry.value === "IN" && (
                <Flex1>
                  <TextField
                    name="county"
                    label={t("Taluka")}
                    theref={register({
                      required: false,
                    })}
                    formState={formState}
                    readOnly={useHqAddress}
                    errors={errors}
                    type="text"
                  />
                </Flex1>
              )}
            </Flex>
            <Flex>
              <Flex2>
                <TextField
                  name="city"
                  label={t("City")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  readOnly={useHqAddress}
                  errors={errors}
                  type="text"
                />
              </Flex2>
              <Flex1>
                <TextField
                  name="postal_code"
                  autoComplete="postal-code"
                  label={getZipCodeInputLabel(selectedCountry.value, t)}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  readOnly={useHqAddress}
                  errors={errors}
                  type="text"
                />
              </Flex1>
            </Flex>
          </>
        )}

        <H4Normal style={{ marginTop: "35px" }}>
          {t("Point of Contact")}
        </H4Normal>
        <RadioButtonContainer>
          <RadioButton
            name={"existingUser"}
            value="existing"
            checked={pointOfContactType === "existing"}
            optionTitle="Existing User"
            handleChange={changePointOfContact}
            readOnly={useHqAddress}
          />
          <RadioButton
            name={"manualUser"}
            value="manual"
            checked={pointOfContactType === "manual"}
            optionTitle="Add Manually"
            handleChange={changePointOfContact}
            readOnly={useHqAddress}
          />
        </RadioButtonContainer>
        {pointOfContactType === "existing" && (
          <Controller
            as={SelectBoxV2}
            control={control}
            name="point_of_contact_id"
            onInputChange={handleUserSearch}
            placeholder={t("Select User")}
            options={userOptions}
            rules={{
              required: true,
            }}
            errors={errors}
            formState={formState}
            disabled={useHqAddress}
          />
        )}
        {pointOfContactType === "manual" && (
          <>
            <TextField
              name="contact_first_name"
              label={t("First Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              readOnly={useHqAddress}
              errors={errors}
              type="text"
            />
            <TextField
              name="contact_last_name"
              label={t("Last Name")}
              theref={register({
                required: true,
              })}
              formState={formState}
              readOnly={useHqAddress}
              errors={errors}
              type="text"
            />
            <TextField
              name="email_address"
              autoComplete="email"
              label={t("Email")}
              theref={register({
                required: true,
              })}
              formState={formState}
              readOnly={useHqAddress}
              errors={errors}
              type="email"
            />
            <Flex>
              <Flex1>
                <Controller
                  as={SelectBoxV2}
                  control={control}
                  name="country_code"
                  autoComplete="countryCode"
                  placeholder={t("Country Code")}
                  id="countryCodeSelectBox"
                  options={getPhoneCodesOptions()}
                  rules={{
                    required: true,
                  }}
                  errors={errors}
                  formState={formState}
                  disabled={useHqAddress}
                />
              </Flex1>
              <Flex2 style={{ marginRight: 0, marginLeft: "14px" }}>
                <TextField
                  name="phone_number"
                  autoComplete="tel"
                  label={t("Phone Number")}
                  theref={register({
                    required: true,
                  })}
                  formState={formState}
                  readOnly={useHqAddress}
                  errors={errors}
                  type="tel"
                />
              </Flex2>
            </Flex>
          </>
        )}

        {/* Conditionally render the toggle switch for "Same as HQ Address" */}
        {hqAddress && (
          <div style={{ margin: "20px 0" }}>
            <ToggleSwitch
              label={t("Same as HQ Address")}
              name="useHqAddress"
              theref={register({ required: false })}
              isChecked={useHqAddress}
              onClick={() => {
                setUseHqAddress((prevState) => {
                  const newState = !prevState;
                  handleToggle(hqAddress || address, newState);
                  setValue("same_as_hq", newState);
                  return newState;
                });
              }}
            />
          </div>
        )}

        <SubmitButtonContainer>
          <PrimaryButtonMedium loading={submitting}>
            {t("Save your changes")}
          </PrimaryButtonMedium>
        </SubmitButtonContainer>
      </Form>

      <Modal
        overlay
        show={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        modalWidth={"680px"}
      >
        <ModalWrapper>
          <WarningTitle>
            {t(
              "This address is currently used in an active transaction or sample request"
            )}
          </WarningTitle>
          <div>
            {t(
              "Your changes made to this address will be saved, but will not be applied to any active transactions or sample requests. Please contact Support if you would like to update any active transactions or sample requests with these changes."
            )}
          </div>
          <div>{t("Would you like to proceed?")}</div>
          <PrimaryButtonMedium
            onClick={proceedAddressChanges}
            style={{ marginTop: "30px" }}
          >
            {t("Proceed")}
          </PrimaryButtonMedium>
          <br />
          <CancelButton
            onClick={cancelEditAddress}
            style={{ padding: "20px 30px" }}
          >
            {t("Cancel")}
          </CancelButton>
        </ModalWrapper>
      </Modal>
    </>
  );
};
