import type { AxiosError } from "axios";
import Axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import useSWR from "swr";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import {
  ButtonWithConfirmDialog,
  CancelButton,
  GoBackButton,
  PrimaryButtonFitContainer,
} from "../../../components/Buttons/Buttons";
import { ContactInfoBlockSmall } from "../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";
import { ErrorPlaceholder } from "../../../components/Error";
import { IDDesktop } from "../../../components/IDs/IDs";
import type { KeyValuePair } from "../../../components/KeyValueDisplay";
import { KeyValueDisplay } from "../../../components/KeyValueDisplay";
import { HeaderLeft, HeaderRight } from "../../../components/Layout/Layout";
import { Notifications } from "../../../components/Notifications/NotificationsContext";
import {
  PriceDetails,
  calculatePriceDetails,
} from "../../../components/PriceDetails/PriceDetails";
import { BuyerQuoteStatus } from "../../../components/QuoteStatus";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { TaxExemptDocument } from "../../../components/TaxExempt/TaxExemptDocument";
import { Timeline } from "../../../components/Timeline/Timeline";
import { CartItemBuyer } from "../../../components/TransactionItem/CartItemBuyer";
import { QuoteItemCardBuyerHasResponse } from "../../../components/TransactionItem/QuoteItemCardBuyerHasResponse";
import {
  H3,
  SoftHeader,
  SoftHeaderPrimaryTextColor,
} from "../../../components/Typography/Typography";
import type { ViewPriceTiersFormProps } from "../../../components/ViewPriceTiersForm/ViewPriceTiersForm";
import { ViewPriceTiersForm } from "../../../components/ViewPriceTiersForm/ViewPriceTiersForm";
import type { ISubmitQuoteItemForm } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { BuyerQuoteItemForm } from "../../../components/quoteCart/BuyerQuoteItemForm";
import { endpoints } from "../../../endpoints";
import {
  Card,
  PageHeader,
  PageTitle,
  PageWrapper,
  TwoColumnOrderTotalSection,
} from "../../../layout/portalPageLayout";
import {
  DetailPageContentWrapper,
  KeyValueContainer,
  QuoteOrderContent,
  QuoteTermsContainer,
  TimelineWrapper,
  WideDetails,
} from "../../../layout/shared/DetailPageLayout/DetailPageLayout";
import { screenSize } from "../../../theme";
import type {
  AccountManagerContactInfo,
  IQuoteRequestItemPatchArgs,
  ITenantCustomerSettings,
  OrderMessageArgs,
  PaginatedTransactionEvents,
  Product,
  QuoteRequest,
  QuoteRequestItem,
  QuoteRequestPatchArgs,
} from "../../../types/types";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import {
  formatDateTime,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import { Flex } from "../../../layout/FormLayout";
import { UnlistedQuoteItemForm } from "../RequestUnlistedProduct/UnlistedQuoteItemForm";
import { TransactionsDocumentView } from "../../../components/DocumentView/TransactionsDocumentView";
import { AddDocumentToTransaction } from "../../SharedPages/AddDocumentToTransaction/AddDocumentToTransaction";
import { get_existing_documents } from "../../SharedPages/AddDocumentToTransaction/utils";

export const HorizontalSeparator = styled.ol`
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 0;
`;

const ItemsHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CancelButtonColumn = styled.div`
  margin-top: 20px;
`;

export const PriceDetailsColumn = styled.div`
  width: 340px;
  @media ${screenSize.small} {
    width: 100%;
  }
  & > button {
    margin-top: 24px;
  }
`;

interface IEditItemFormData {
  product?: Product;
  quoteItem: QuoteRequestItem;
}

export const BuyerQuoteDetail = providePrivatePageProps(({ user }) => {
  const {
    storefront_id,
    tenant_id,
    storefront_metadata: { enable_invoice_auto_generation },
  } = useStoreState();
  const { quoteId } =
    useParams<{
      quoteId: string;
    }>();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
    status: ArrayParam,
  });
  const history = useHistory();

  const { t } = useTranslation();

  const { notifySuccess, notifyError } = useContext(Notifications);
  const [editItemFormData, setEditItemFormData] = useState<IEditItemFormData>();
  const [isAcceptingQuote, setIsAcceptingQuote] = useState(false);
  const [isCancellingQuote, setIsCancellingQuote] = useState(false);
  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const { mutateNotifications } = useInAppNotifications(storefront_id, user);
  // If the price tiers form props are defined we show the form.
  const [priceTiersFormProps, setPriceTiersFormProps] =
    useState<ViewPriceTiersFormProps | null>(null);

  const { accountPath } = useRoutePath();

  const {
    data: quote,
    error: quoteError,
    mutate: mutateQuote,
  } = useSWR<QuoteRequest>(
    quoteId ? `/v1/storefronts/${storefront_id}/quotes/${quoteId}` : null
  );

  const { data: settings } = useSWR<ITenantCustomerSettings>(
    quote
      ? endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
          storefront_id,
          quote.seller_id,
          quote.buyer_id
        )
      : null
  );

  let accountManagerContactInfo: AccountManagerContactInfo | undefined =
    undefined;
  if (quote && quote.sold_by) {
    accountManagerContactInfo = quote.sold_by.tenant_user_contact_info;
  }

  const { data, mutate: mutateEvents } = useSWR<
    PaginatedTransactionEvents,
    AxiosError
  >(
    quoteId
      ? `/v1/storefronts/${storefront_id}/quotes/${quoteId}/events?limit=100`
      : null,
    {
      onSuccess: (events) => events.data.reverse(),
    }
  );

  const mutateQuoteAndEvents = () => {
    return Promise.allSettled([mutateQuote(), mutateEvents()]);
  };

  const quoteEvents = data?.data;

  const isLoading = !quote && !quoteError;
  if (isLoading) {
    // TODO: add an actual loading state here?
    return null;
  }

  const somePriceTiersExpired =
    quote &&
    quote.status === "responded" &&
    quote.items.some((item) => !item.price_per_unit);

  // Ideally this would have line breaks in the modal but we don't have
  // support for that.
  const priceTiersExpiredMessage =
    t(`This quote can no longer be accepted because the price offers have expired for at least one of the items in the quote. Here are your options:
1. Delete the item(s) that have the expired price offers and then accept the rest of the quote request.
2. Cancel the quote request and submit a new one.
`);

  if (quote) {
    mutateNotifications();
  }

  if (quoteError) {
    return <ErrorPlaceholder message="An error occurred" />;
  }

  if (quote) {
    const shipmentEta = moment(quote.required_eta).format("MMMM DD, YYYY");
    const validToDate = quote.valid_to_date
      ? moment(quote.valid_to_date).format("MMMM DD, YYYY")
      : undefined;

    const terms: KeyValuePair[] = [
      { key: t("Shipping Terms"), value: quote.delivery_term?.name || "--" },
      { key: t("Payment Terms"), value: quote.payment_term?.name || "--" },
      { key: t("Shipment ETA"), value: shipmentEta || "--" },
      { key: t("Payment Method"), value: quote.payment_mode?.name || "--" },
    ];

    const respondedTerms = [
      ...terms,
      { key: t("Price Valid Until"), value: validToDate || "--" },
    ];

    const editQuoteItem = async (quoteItem: QuoteRequestItem) => {
      if (quoteItem.product_id) {
        try {
          const { data } = await Axios.get<Product>(
            endpoints.v2_tenants_id_pim_products_id(
              tenant_id,
              quoteItem.product_id
            )
          );
          setEditItemFormData({ quoteItem, product: data });
        } catch (error) {
          notifyError(t("An error occurred"), { error });
        }
      } else {
        setEditItemFormData({ quoteItem });
      }
    };

    const removeQuoteItem = async (quoteItem: QuoteRequestItem) => {
      try {
        await Axios.delete(
          `/v1/storefronts/${storefront_id}/quotes/${quote.id}/items/${quoteItem.id}`
        );
        notifySuccess(t("Item successfully removed"));
        mutateQuote();
      } catch (error) {
        notifyError(t("An error occurred"), { error });
      }
    };

    const handleCloseSlideOut = () => {
      setEditItemFormData(undefined);
    };

    const handleSendMessage = async (message: string) => {
      try {
        await Axios.post<OrderMessageArgs>(
          `/v1/storefronts/${storefront_id}/quotes/${quoteId}/messages`,
          {
            message: message,
            message_type: "Other",
          }
        );
        await mutateEvents();
      } catch (error) {
        console.error(error);
      }
    };

    const handleCancelRequest = async () => {
      try {
        setIsCancellingQuote(true);
        await Axios.patch(
          `/v1/storefronts/${storefront_id}/quotes/${quote.id}/cancel`
        );
        notifySuccess(
          t(`Quote #{{number}} cancelled`, { number: quote.number })
        );
        mutateQuote();
        mutateEvents();
      } catch (error) {
        notifyError(t("An error occurred"), { error });
      } finally {
        setIsCancellingQuote(false);
      }
    };

    /**
     * Returns a function that will be passed to the quote item form component
     * where it is called to submit the form. The returned function captures the
     * item ID (via closure) so the ID can be used to submit the form.
     */
    const makeSubmitQuoteItemFormFunction = (
      itemId: string
    ): ISubmitQuoteItemForm => {
      const submitQuoteItemForm: ISubmitQuoteItemForm = async (
        unifiedCartArg
      ) => {
        const {
          applications,
          custom_application,
          buyer_id,
          currency,
          custom_packaging_quantity,
          custom_packaging_type_id,
          delivery_term_id,
          no_of_units,
          packaging_unit_id,
          payment_term_id,
          price_per_unit,
          shipping_address_id,
          sku_id,
          total_quantity,
        } = unifiedCartArg;

        const quoteRequestPatchArgs: QuoteRequestPatchArgs = {
          shipping_address_id,
          delivery_term_id,
          payment_term_id,
        };

        const quoteRequest = Axios.patch(
          `/v1/storefronts/${storefront_id}/quotes/${quote.id}`,
          quoteRequestPatchArgs
        );

        const itemPatchArgs: IQuoteRequestItemPatchArgs = {
          applications,
          ...(custom_application ? { custom_application } : {}),
          buyer_id,
          currency,
          custom_packaging_quantity,
          custom_packaging_type_id,
          no_of_units,
          packaging_unit_id,
          price_per_unit,
          // We need to also patch the `requested` versions of some properties
          // so that they stay in-sync with the non-requested versions.
          requested_sku_id: sku_id,
          requested_no_of_units: no_of_units,
          requested_total_quantity: total_quantity,
          sku_id,
          total_quantity,
        };

        const itemRequest = Axios.patch(
          `/v1/storefronts/${storefront_id}/quotes/${quote.id}/items/${itemId}`,
          itemPatchArgs
        );
        // Item request should come before quote request
        // Because quote request triggers an email, dependent on values updated by item request.
        await Promise.all([itemRequest, quoteRequest]);
        await mutateQuote();
        await mutateEvents();
        setEditItemFormData(undefined);
      };
      return submitQuoteItemForm;
    };

    const handleAcceptQuote = async () => {
      try {
        setIsAcceptingQuote(true);
        const {
          data: { purchase_order_id },
        } = await Axios.patch(
          `/v1/storefronts/${storefront_id}/quotes/${quote.id}/accept`
        );
        if (quote.auto_accept && enable_invoice_auto_generation) {
          notifySuccess(
            t(
              "Quote {{quoteNumber}} accepted. Invoice will be generated shortly.",
              { quoteNumber: quote.number }
            )
          );
        } else {
          notifySuccess(
            t("Quote {{quoteNumber}} accepted", { quoteNumber: quote.number })
          );
        }
        mutateQuote();
        mutateEvents();
        setIsAcceptingQuote(false);
        history.push(`${accountPath}/orders/${purchase_order_id}`);
      } catch (error) {
        notifyError(t("An error occurred"), { error });
        setIsAcceptingQuote(false);
      }
    };

    const { subtotalString, totalString } = calculatePriceDetails({
      items: quote.items,
      fees: quote.fees,
      currencyCode: quote.currency,
    });

    const quoteIsCancelledOrDeclined =
      quote.status === "cancelled" || quote.status === "declined";

    const params = new URLSearchParams();
    params.append("offset", String(query?.offset ?? 0));
    params.append("perPage", String(query?.perPage ?? 10));
    if (query.q) {
      params.append("q", query.q);
    }
    const query_status = (query?.status ?? []).filter(
      (status) => !!status
    ) as string[];
    query_status.forEach((status) => params.append("status", status));
    return (
      <PageWrapper>
        <Link to={`${accountPath}/quotes?${params}`}>
          <GoBackButton text={"Quotes"} />
        </Link>
        <PageHeader>
          <HeaderLeft>
            <PageTitle>{t("Quote")}</PageTitle>
            <IDDesktop>{`${quote.number}`}</IDDesktop>
          </HeaderLeft>
          <HeaderRight data-testid={"quote-detail-status-container"}>
            <BuyerQuoteStatus status={quote.status} position={"right"} t={t} />
          </HeaderRight>
        </PageHeader>
        <Flex>
          <SoftHeader style={{ margin: "0 3px 5px 0" }}>
            {t("Created By")}:
          </SoftHeader>
          <SoftHeaderPrimaryTextColor>
            {`${quote.created_by || `--`} (${quote.created_by_company_name})`}
          </SoftHeaderPrimaryTextColor>
        </Flex>
        <Flex>
          <SoftHeader style={{ margin: "0 3px 0 0" }}>
            {t("Created On")}:
          </SoftHeader>
          <SoftHeaderPrimaryTextColor>
            {formatDateTime(quote.created_at)}
          </SoftHeaderPrimaryTextColor>
          {(formatDateTime(quote.modified_at) !==
            formatDateTime(quote.created_at) ||
            quote.created_by !== quote.modified_by) && (
            <>
              <SoftHeader style={{ margin: "0 2px 0 10px" }}>
                {t("Last Modified")}:
              </SoftHeader>
              <SoftHeaderPrimaryTextColor>
                {quote.modified_at
                  ? `${formatDateTime(quote.modified_at)} By ${
                      quote.modified_by
                    }`
                  : `--`}
              </SoftHeaderPrimaryTextColor>
            </>
          )}
        </Flex>
        <HorizontalSeparator />
        <DetailPageContentWrapper>
          <QuoteOrderContent>
            <Card>
              <H3>{t("Summary")}</H3>
              <WideDetails>
                <ContactInfoBlockSmall
                  address={quote.shipping_address}
                  header={t("Ship to")}
                  testid={"shipping-address"}
                />
                <ContactInfoBlockSmall
                  address={quote.billing_address}
                  header={t("Bill to")}
                  testid={"billing-address"}
                />
                <ContactInfoBlockSmall
                  address={quote.seller_address}
                  accountManagerContactInfo={accountManagerContactInfo}
                  header={t("Sold By")}
                  testid={"shipping-address"}
                />
              </WideDetails>
              <ItemsHeaderRow>
                <H3>{t("Items")}</H3>
              </ItemsHeaderRow>
              {quote.items.map((item, index) => {
                if (quote.status === "requested") {
                  // No response from the seller (yet).
                  return (
                    <CartItemBuyer
                      item={item}
                      index={index}
                      editItem={editQuoteItem}
                      removeItem={removeQuoteItem}
                      key={item.id}
                    />
                  );
                }
                // The seller has responded.
                // quote.status may be "responded", but also "cancelled", etc.
                const { status } = quote;
                const isReadOnly =
                  status === "cancelled" ||
                  status === "declined" ||
                  status === "accepted" ||
                  status === "expired";

                return (
                  <QuoteItemCardBuyerHasResponse
                    item={item}
                    index={index}
                    quoteIsCancelledOrDeclined={quoteIsCancelledOrDeclined}
                    removeCartItem={removeQuoteItem}
                    viewPriceTiers={(tiersData) => {
                      setPriceTiersFormProps({
                        tiersData: tiersData,
                        quoteId: quoteId,
                        quoteItem: item,
                        closeForm: () => setPriceTiersFormProps(null),
                        mutateQuoteAndEvents,
                        isReadOnly,
                        titleText: "Offers",
                      });
                    }}
                    quoteId={quoteId}
                    key={item.id}
                  />
                );
              })}
              <TwoColumnOrderTotalSection>
                <QuoteTermsContainer>
                  <KeyValueContainer>
                    <KeyValueDisplay
                      data={
                        quote.status === "responded" ? respondedTerms : terms
                      }
                    />
                    {settings?.is_tax_exempt &&
                      settings?.tax_exempt_document && (
                        <TaxExemptDocument
                          document={settings.tax_exempt_document}
                        />
                      )}
                  </KeyValueContainer>
                  <CancelButtonColumn>
                    {(quote.status === "new" ||
                      quote.status === "requested" ||
                      quote.status === "responded") && (
                      <ButtonWithConfirmDialog
                        Button={CancelButton}
                        buttonText={t("Cancel Request")}
                        loading={isCancellingQuote}
                        confirmMessage={t(
                          "Are you sure you want to cancel this request?"
                        )}
                        handleConfirm={handleCancelRequest}
                        testid="cancel-quote-button"
                      />
                    )}
                  </CancelButtonColumn>
                </QuoteTermsContainer>
                <PriceDetailsColumn>
                  {/* For status "new" or "requested" hide price details. */}
                  {(quote.status === "responded" ||
                    quote.status === "cancelled" ||
                    quote.status === "declined" ||
                    quote.status === "accepted" ||
                    quote.status === "expired") && (
                    <PriceDetails
                      subtotal={subtotalString}
                      total={totalString}
                      fees={quote.fees}
                      currencyCode={quote.currency}
                    />
                  )}
                  {quote.status === "responded" &&
                    (somePriceTiersExpired ? (
                      <ButtonWithConfirmDialog
                        Button={PrimaryButtonFitContainer}
                        testid={"accept-quote-button"}
                        buttonText={t("Place Order")}
                        confirmMessage={priceTiersExpiredMessage}
                      />
                    ) : (
                      <PrimaryButtonFitContainer
                        loading={isAcceptingQuote}
                        onClick={handleAcceptQuote}
                      >
                        {t("Place Order")}
                      </PrimaryButtonFitContainer>
                    ))}
                </PriceDetailsColumn>
              </TwoColumnOrderTotalSection>
            </Card>
            <SlideOut
              show={showAddDocumentForm}
              closeFlyout={() => setShowAddDocumentForm(false)}
            >
              <AddDocumentToTransaction
                existing_documents={get_existing_documents({
                  items: [],
                  seller_or_buyer_documents: quote.buyer_documents,
                })}
                products={quote.items.map(
                  ({ product: { name }, product_id }) => ({
                    name,
                    id: product_id,
                  })
                )}
                transaction_type="quotes"
                transaction_type_id={quote.id}
                fetchData={mutateQuoteAndEvents}
                onComplete={() => setShowAddDocumentForm(false)}
              />
            </SlideOut>
            <TransactionsDocumentView
              items={quote.items}
              buyer_documents={quote.buyer_documents}
              seller_documents={quote.seller_documents}
              onAddDocumentClick={() => setShowAddDocumentForm(true)}
              status={quote.status}
              buyer_role={quote.buyer_role}
            />
          </QuoteOrderContent>
          <TimelineWrapper>
            {quoteEvents && quote && (
              <Timeline
                messages={quoteEvents}
                loggedInUser={user}
                fetchingData={false}
                sendMessage={handleSendMessage}
                quote={quote}
              />
            )}
          </TimelineWrapper>
        </DetailPageContentWrapper>

        <SlideOut closeFlyout={handleCloseSlideOut} show={!!editItemFormData}>
          {editItemFormData && (
            <>
              {editItemFormData.product && (
                <BuyerQuoteItemForm
                  editingExistingItem={true}
                  product={editItemFormData.product}
                  product_application={
                    editItemFormData.quoteItem.product_applications[0]
                  }
                  submitQuoteItemForm={makeSubmitQuoteItemFormFunction(
                    editItemFormData.quoteItem.id
                  )}
                  buyerUser={user}
                  productSku={editItemFormData.quoteItem.sku}
                  no_of_units={editItemFormData.quoteItem.no_of_units}
                  total_quantity={editItemFormData.quoteItem.total_quantity}
                  custom_packaging_quantity={
                    editItemFormData.quoteItem.custom_packaging_quantity
                  }
                  // Transaction values.
                  shippingAddress={quote.shipping_address}
                  paymentTerm={quote.payment_term}
                  deliveryTerm={quote.delivery_term}
                  allowEditingTransactionValues={false}
                  currencyCode={
                    editItemFormData.quoteItem.currency || quote.currency
                  }
                />
              )}
              {!editItemFormData.product && (
                <UnlistedQuoteItemForm
                  quoteItems={(quote as QuoteRequest).items.map(
                    (item) => item.product.name
                  )}
                  editingExistingItem={true}
                  submitQuoteItemForm={makeSubmitQuoteItemFormFunction(
                    editItemFormData.quoteItem.id
                  )}
                  buyerUser={user}
                  product_application={
                    editItemFormData.quoteItem.product_applications[0]
                  }
                  product_name={editItemFormData.quoteItem.product.name}
                  productSku={editItemFormData.quoteItem.sku}
                  no_of_units={editItemFormData.quoteItem.no_of_units}
                  total_quantity={editItemFormData.quoteItem.total_quantity}
                  customApplication={
                    editItemFormData.quoteItem.custom_application
                  }
                  custom_packaging_quantity={
                    editItemFormData.quoteItem.custom_packaging_quantity
                  }
                  // TODO: how can quoteRequest not be defined here?
                  shippingAddress={quote?.shipping_address}
                  paymentTerm={quote?.payment_term}
                  deliveryTerm={quote?.delivery_term}
                  allowEditingTransactionValues={false}
                  currencyCode={editItemFormData.quoteItem.currency}
                  disableProductName={true}
                />
              )}
            </>
          )}
        </SlideOut>

        <SlideOut
          closeFlyout={() => setPriceTiersFormProps(null)}
          show={!!priceTiersFormProps}
        >
          {priceTiersFormProps && (
            <ViewPriceTiersForm {...priceTiersFormProps} />
          )}
        </SlideOut>
      </PageWrapper>
    );
  }

  return null;
});
