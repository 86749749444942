import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AgilisButton,
  ButtonWithConfirmDialog,
  CancelButton,
  GoBackButton,
} from "../../../components/Buttons/Buttons";
import {
  PageWrapper,
  PageHeader,
  PageTitle,
  Card,
  TwoColumnOrderTotalSection,
} from "../../../layout/portalPageLayout";
import { ErrorPlaceholder } from "../../../components/Error";
import type {
  StripePaymentLinkSchema,
  IPurchaseOrder,
  OrderMessageArgs,
  PaginatedTransactionEvents,
  ITenantCustomerSettings,
  OrderStatus,
  QuoteRequest,
  User,
  DataMutate,
  OrderDocument,
  IQuoteRequest,
  AccountManagerContactInfo,
} from "../../../types/types";
import useSWR from "swr";
import { IDDesktop } from "../../../components/IDs/IDs";
import {
  DetailPageContentWrapper,
  KeyValueContainer,
  QuoteOrderContent,
  QuoteTermsContainer,
  TimelineWrapper,
  WideDetails,
} from "../../../layout/shared/DetailPageLayout/DetailPageLayout";
import type { KeyValuePair } from "../../../components/KeyValueDisplay";
import { KeyValueDisplay } from "../../../components/KeyValueDisplay";
import moment from "moment";
import {
  H3,
  InnerTitle,
  SoftHeader,
  SoftHeaderMediumDarkText,
  SoftHeaderPrimaryTextColor,
} from "../../../components/Typography/Typography";
import { HeaderLeft, HeaderRight } from "../../../components/Layout/Layout";
import {
  calculatePriceDetails,
  PriceDetails,
} from "../../../components/PriceDetails/PriceDetails";
import { ContactInfoBlockSmall } from "../../../components/ContactInfoBlockSmall/ContactInfoBlockSmall";
import {
  CancelButtonColumn,
  HorizontalSeparator,
  PriceDetailsColumn,
} from "../BuyerQuoteDetail/BuyerQuoteDetail";
import { TransactionStatusPath } from "../../../components/OrderStatusPath/OrderStatusPath";
import { BuyerOrderStatus } from "../../../components/OrderStatus/OrderStatus";
import { AddPO } from "./AddPO";
import { InvoiceSection } from "../../SharedPages/SellerOrderDetailPage/InvoiceSection/InvoiceSection";
import { providePrivatePageProps, useRoutePath } from "../../../util/Routing";
import { Timeline } from "../../../components/Timeline/Timeline";
import type { AxiosError } from "axios";
import Axios from "axios";
import { Loader } from "../../../components/Loader/Loader";
import { useNotifications } from "../../../components/Notifications/NotificationsContext";
import { ShipmentAdviceSection } from "../../../components/ShipmentAdviceSection/ShipmentAdviceSection";
import { OrderItem } from "../../../components/TransactionItem/OrderItem";
import { PackingListSection } from "../../SharedPages/SellerOrderDetailPage/PackingList/PackingList";
import { useTranslation } from "react-i18next";
import {
  formatDateTime,
  isAxiosError,
  useInAppNotifications,
  useStoreState,
} from "../../../util/util";
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { ExternalLinkIcon, LoadingIcon } from "../../../components/Icons/Icons";
import { useTheme } from "styled-components/macro";
import { endpoints } from "../../../endpoints";
import { TaxExemptDocument } from "../../../components/TaxExempt/TaxExemptDocument";
import { GenericDialogBody } from "../../../components/ConfirmDialog/ConfirmDialog";
import { Flex } from "../../../layout/FormLayout";
import { TransactionsDocumentView } from "../../../components/DocumentView/TransactionsDocumentView";
import { SlideOut } from "../../../components/SlideOut/SlideOut";
import { AddDocumentToTransaction } from "../../SharedPages/AddDocumentToTransaction/AddDocumentToTransaction";
import { get_existing_documents } from "../../SharedPages/AddDocumentToTransaction/utils";

const BuyerOrderDetail = ({
  order,
  user,
  quote,
  mutateOrder,
}: {
  order: IPurchaseOrder;
  user: User;
  quote?: QuoteRequest;
  mutateOrder: DataMutate<IPurchaseOrder>;
}) => {
  const { accountPath } = useRoutePath();
  const {
    storefront_id,
    storefront_metadata: { accepts_payments },
  } = useStoreState();
  const { notifyError, notifySuccess } = useNotifications();
  const { t } = useTranslation();
  const { mutateNotifications } = useInAppNotifications(storefront_id, user);
  const theme = useTheme();
  const [invoiceDocument, setInvoiceDocument] = useState<OrderDocument>();
  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const [query, setQuery] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    status: ArrayParam,
    perPage: NumberParam,
    success: BooleanParam,
  });
  const [paymentInProcess, setPaymentInProcess] = useState(false);

  const shipmentEta = moment(order.required_eta).format("MMMM DD, YYYY");
  const orderIsReorder = !order.is_from_quote;
  const isNewOrder = order.status === "new";

  mutateNotifications();

  const { data: orderEvents, mutate: mutateEvents } = useSWR<
    PaginatedTransactionEvents,
    AxiosError
  >(
    order.id
      ? `/v1/storefronts/${storefront_id}/orders/${order.id}/events?limit=100`
      : null,
    {
      onSuccess: (orders) => orders.data.reverse(),
    }
  );

  const { data: settings } = useSWR<ITenantCustomerSettings>(
    endpoints.v1_storefronts_id_tenants_id_customers_id_settings(
      storefront_id,
      order.seller_id,
      order.buyer_id
    )
  );

  let accountManagerContactInfo: AccountManagerContactInfo | undefined =
    undefined;
  if (order && order.sold_by) {
    accountManagerContactInfo = order.sold_by.tenant_user_contact_info;
  }

  const terms: KeyValuePair[] = [
    { key: t("Shipping Terms"), value: order.delivery_term?.name || "--" },
    { key: t("Payment Terms"), value: order.payment_term?.name || "--" },
    { key: t("Shipment ETA"), value: shipmentEta || "--" },
    { key: t("Payment Method"), value: order.payment_mode?.name || "--" },
  ];

  const handlePaymentClick = async () => {
    try {
      setPaymentInProcess(true);
      const { data: response } = await Axios.post<StripePaymentLinkSchema>(
        `/v1/storefronts/${storefront_id}/orders/${order.id}/payments`
      );
      if (response.url) {
        const tab = window.open(response.url, "_blank");
        tab?.focus();
      }
    } catch (error) {
      if (isAxiosError(error)) {
        notifyError(error.response?.data.message);
      } else {
        notifyError(t("Error starting payment process"));
      }
    } finally {
      setPaymentInProcess(false);
    }
  };

  const mutateOrderAndEvents = async () => {
    await mutateOrder();
    await mutateEvents();
  };

  const handleSendMessage = async (message: string) => {
    try {
      await Axios.post<OrderMessageArgs>(
        `/v1/storefronts/${storefront_id}/orders/${order.id}/messages`,
        {
          message: message,
          message_type: "Other",
        }
      );
      await mutateEvents();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOrderCancel = async (id: string) => {
    try {
      await Axios.patch(`/v1/storefronts/${storefront_id}/orders/${id}/cancel`);
      notifySuccess(
        t(`Order {{number}} has been cancelled`, { number: order.number })
      );
      await mutateOrderAndEvents();
    } catch (error) {
      notifyError(
        t(`There was an error cancelling order {{number}}`, {
          number: order.number,
        })
      );
    }
  };

  const canShowStatusPath = () =>
    order.status !== "declined" && order.status !== "cancelled";

  const showPayButton =
    order.status === "invoiced" &&
    invoiceDocument &&
    /**
     * Check to see if invoice is generated based off of name.
     * This is kind of flimsy but there is no other way to tell the difference
     */
    invoiceDocument.name === `invoice_${order.number}.pdf` &&
    order.currency === "USD" &&
    accepts_payments &&
    order.payment_mode.payments_enabled;

  const maybeInvoiceCharges = (() => {
    if (order.invoice && order.invoice.additional_charges) {
      return order.invoice.additional_charges;
    } else return [];
  })();

  const feesAndCharges = [...order.fees, ...maybeInvoiceCharges];

  const { subtotalString, totalString } = calculatePriceDetails({
    items: order.items,
    fees: feesAndCharges,
    currencyCode: order.currency,
  });

  if (query.success) {
    setQuery({ success: undefined });
    notifySuccess("Payment sent successfully");
  }

  const urlParams = (() => {
    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(query?.offset ?? 0));
    params.append("perPage", String(query?.perPage ?? 10));
    (
      (query?.status ?? []).filter(
        (status) => typeof status === "string"
      ) as string[]
    ).forEach((status) => params.append("status", status));
    return params;
  })();

  const CancelOrderButton = (props: object) => (
    <CancelButton {...props} type={"button"}>
      {t("Cancel this order")}
    </CancelButton>
  );

  useEffect(() => {
    setInvoiceDocument(order.documents.find((doc) => doc.kind === "invoice"));
  }, [order]);

  return (
    <PageWrapper>
      <Link to={`${accountPath}/orders?${urlParams}`}>
        <GoBackButton text={t("Orders")} />
      </Link>
      <PageHeader>
        <HeaderLeft>
          <PageTitle>{t("Order")}</PageTitle>
          <IDDesktop>{`${order.number}`}</IDDesktop>
        </HeaderLeft>
        <HeaderRight>
          <BuyerOrderStatus t={t} status={order.status} position={"right"} />
        </HeaderRight>
      </PageHeader>
      <Flex>
        <SoftHeader style={{ margin: "0 3px 5px 0" }}>
          {t("Created By")}:
        </SoftHeader>
        <SoftHeaderPrimaryTextColor>
          {`${order.created_by || `--`} (${order.created_by_company_name})`}
        </SoftHeaderPrimaryTextColor>
      </Flex>
      <Flex>
        <SoftHeader style={{ margin: "0 3px 0 0" }}>
          {t("Created On")}:
        </SoftHeader>
        <SoftHeaderPrimaryTextColor>
          {formatDateTime(order.created_at)}
        </SoftHeaderPrimaryTextColor>
        {(formatDateTime(order.modified_at) !==
          formatDateTime(order.created_at) ||
          order.created_by !== order.modified_by) && (
          <>
            <SoftHeader style={{ margin: "0 2px 0 10px" }}>
              {t("Last Modified")}:
            </SoftHeader>
            <SoftHeaderPrimaryTextColor>
              {order.modified_at
                ? `${formatDateTime(order.modified_at)} By ${order.modified_by}`
                : `--`}
            </SoftHeaderPrimaryTextColor>
          </>
        )}
      </Flex>
      <HorizontalSeparator />
      <DetailPageContentWrapper>
        <QuoteOrderContent>
          {canShowStatusPath() && (
            <TransactionStatusPath
              transactionStatus={order.status}
              view="buyerOrder"
            />
          )}
          <Card>
            <H3>{t("Summary")}</H3>
            <WideDetails>
              <ContactInfoBlockSmall
                address={order.shipping_address}
                header={t("Ship to")}
                testid={"shipping-address"}
              />
              <ContactInfoBlockSmall
                address={order.billing_address}
                header={t("Bill to")}
                testid={"billing-address"}
              />
              <ContactInfoBlockSmall
                address={order.seller_address}
                accountManagerContactInfo={accountManagerContactInfo}
                header={t("Sold By")}
                testid={"shipping-address"}
              />
            </WideDetails>
            <H3>{t("Items")}</H3>
            {order.items.map((item, index) => {
              return <OrderItem item={item} index={index} key={index} t={t} />;
            })}
            <TwoColumnOrderTotalSection>
              <QuoteTermsContainer>
                <KeyValueContainer>
                  <KeyValueDisplay data={terms} />
                  {settings?.is_tax_exempt && order?.tax_exempt_document && (
                    <TaxExemptDocument document={order.tax_exempt_document} />
                  )}
                </KeyValueContainer>

                <CancelButtonColumn>
                  {isNewOrder && (
                    <ButtonWithConfirmDialog
                      Button={CancelOrderButton}
                      testid={"order-decline-button"}
                      handleConfirm={() => handleOrderCancel(order.id)}
                      confirmMessage={t(
                        "Are you sure you want to cancel this order?"
                      )}
                    />
                  )}
                </CancelButtonColumn>
              </QuoteTermsContainer>
              <PriceDetailsColumn>
                <div>
                  <PriceDetails
                    subtotal={subtotalString}
                    total={totalString}
                    fees={feesAndCharges}
                    currencyCode={order.currency}
                  />
                  {showPayButton && (
                    <div style={{ marginTop: "20px" }}>
                      <AgilisButton
                        loading={paymentInProcess}
                        onClick={handlePaymentClick}
                      >
                        {t("Pay Now")}
                      </AgilisButton>
                    </div>
                  )}
                </div>
              </PriceDetailsColumn>
            </TwoColumnOrderTotalSection>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            ></div>
          </Card>
          {order.payments?.receipt_url && (
            <Card>
              <InnerTitle>{t("Receipt")}</InnerTitle>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <a
                  href={order.payments.receipt_url}
                  target="blank"
                  style={{
                    marginRight: "2px",
                    color: theme.primaryLinkColor,
                  }}
                >
                  {order.number} {t("receipt")}
                </a>
                <div style={{ top: "6px" }}>
                  <ExternalLinkIcon />
                </div>
              </div>
            </Card>
          )}
          {invoiceDocument && (
            <Card>
              <InvoiceSection order={order} readOnly={true} />
            </Card>
          )}
          <Card>
            <AddPO order={order} />
          </Card>
          {!isNewOrder && (
            <>
              <Card>
                <H3>{t("Shipment Advice")}</H3>
                {order.shipment_advices &&
                order.shipment_advices?.length > 0 ? (
                  order.shipment_advices.map((advice) => (
                    <ShipmentAdviceSection
                      key={advice.id}
                      shipment_advice={advice}
                      canEdit={false}
                    />
                  ))
                ) : (
                  <span>{t("N/A")}</span>
                )}
              </Card>
            </>
          )}
          {order.alternative_po_number && (
            <Card>
              <PackingListSection
                order={order}
                readOnly={true}
                mutateOrderAndEvents={mutateOrderAndEvents}
              />
            </Card>
          )}
          <SlideOut
            show={showAddDocumentForm}
            closeFlyout={() => setShowAddDocumentForm(false)}
          >
            <AddDocumentToTransaction
              existing_documents={get_existing_documents({
                items: [],
                seller_or_buyer_documents: order.buyer_documents,
              })}
              products={order.items.map(
                ({ product: { name }, product_id }) => ({
                  name,
                  id: product_id,
                })
              )}
              transaction_type="orders"
              transaction_type_id={order.id}
              fetchData={mutateOrderAndEvents}
              onComplete={() => setShowAddDocumentForm(false)}
            />
          </SlideOut>
          <TransactionsDocumentView
            items={order.items}
            buyer_documents={order.buyer_documents}
            seller_documents={order.seller_documents}
            onAddDocumentClick={() => setShowAddDocumentForm(true)}
            status={order.status}
            buyer_role={order.buyer_role}
          />
        </QuoteOrderContent>
        <TimelineWrapper>
          {orderEvents && order && (
            <Timeline
              messages={orderEvents.data}
              loggedInUser={user}
              fetchingData={false}
              sendMessage={handleSendMessage}
              order={order}
              quote={quote ?? ({ number: order.number } as IQuoteRequest)}
            />
          )}
          {orderIsReorder && order && (
            <Timeline
              messages={orderEvents?.data ?? []}
              loggedInUser={user}
              sendMessage={handleSendMessage}
              order={order}
            />
          )}
        </TimelineWrapper>
      </DetailPageContentWrapper>
    </PageWrapper>
  );
};

export const BuyerOrderDetailWrapper = providePrivatePageProps(({ user }) => {
  const { orderId } =
    useParams<{
      orderId: string;
    }>();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<OrderStatus>();
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [autoAccept, setAutoAccept] = useState(false);
  const [hasInvoice, setHasInvoice] = useState(false);
  const [is_invoice_requested, set_is_invoice_requested] = useState(false);
  const [has_shown_invoice_error, set_has_shown_invoice_error] =
    useState(false);
  const [start_time] = useState(Date.now());
  const { t } = useTranslation();
  const { notifyError } = useNotifications();
  const {
    storefront_id,
    storefront_metadata: { enable_invoice_auto_generation },
  } = useStoreState();
  const theme = useTheme();

  const {
    data: order,
    error: orderError,
    mutate: mutateOrder,
  } = useSWR<IPurchaseOrder, AxiosError>(
    orderId ? `/v1/storefronts/${storefront_id}/orders/${orderId}` : null
  );

  const { data: quote } = useSWR<QuoteRequest, AxiosError>(
    order?.quote_request_id
      ? `/v1/storefronts/${storefront_id}/quotes/${order.quote_request_id}`
      : null
  );

  const isLoading = !order && !orderError;

  const allOrdersHaveProducts = order
    ? order.items.every((item) => item.product !== null)
    : true;

  useEffect(() => {
    setSelectedOrderStatus(order?.status ?? undefined);
    setAutoAccept(quote?.auto_accept ?? false);
    setHasInvoice(
      order?.documents.findIndex((doc) => doc.kind === "invoice") !== -1 ??
        false
    );
  }, [order, quote]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;
    const timeout = 30000;
    if (
      order &&
      !hasInvoice &&
      autoAccept &&
      enable_invoice_auto_generation &&
      selectedOrderStatus === "invoiced"
    ) {
      setShowInvoiceDialog(true);
      intervalId = setInterval(() => {
        if (Date.now() - start_time > timeout) {
          clearInterval(intervalId);
          setShowInvoiceDialog(false);
          set_is_invoice_requested(true);
        } else {
          mutateOrder();
        }
      }, 2000);
    }
    if (hasInvoice) {
      setShowInvoiceDialog(false);
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [
    order,
    hasInvoice,
    mutateOrder,
    selectedOrderStatus,
    autoAccept,
    enable_invoice_auto_generation,
    start_time,
    notifyError,
    t,
  ]);

  if (!hasInvoice && is_invoice_requested && !has_shown_invoice_error) {
    notifyError(
      t("Failed to generate an invoice. Please refresh the page to try again.")
    );
    set_has_shown_invoice_error(true);
  }

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  if (orderError) {
    return <ErrorPlaceholder message={t("An error occurred")} />;
  }

  if (!allOrdersHaveProducts) {
    // This has only happened on demo with a user/order imported from V2.
    // Hopefully this code can be removed later.
    return (
      <ErrorPlaceholder
        message={t("There was an error loading this orders products")}
      />
    );
  }

  return order ? (
    <>
      <BuyerOrderDetail
        order={order}
        user={user}
        quote={quote}
        mutateOrder={mutateOrder}
      />
      <GenericDialogBody
        show={showInvoiceDialog}
        closeDialog={() => setShowInvoiceDialog(false)}
      >
        <div
          style={{
            padding: "16px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <LoadingIcon
            width={15}
            height={15}
            fill={theme.primaryButtonTextColor}
          />
          <SoftHeaderMediumDarkText>
            {t("Please wait, your invoice is being generated...")}
          </SoftHeaderMediumDarkText>
        </div>
      </GenericDialogBody>
    </>
  ) : null;
});
