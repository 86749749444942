import type {
  APIMetaData,
  StorefrontEdition,
  StorefrontMetaData,
} from "./types/types";
import React, { createContext, useEffect } from "react";
import type { DefaultTheme } from "styled-components";
import { useCookies } from "react-cookie";

export interface IStoreState {
  // The tenant+storefront slug from the URL, e.g. "acme-pharmaceuticals".
  slug: string | null;
  storefront_id: string;
  tenant_id: string;
  edition: StorefrontEdition;
  analytics_allowed: boolean;
  storefront_metadata: StorefrontMetaData;
  api_metadata: APIMetaData | null;
  // Save a location (URL object) here to allow returning to it later.
  stored_location: string | null;
  theme: DefaultTheme | null;
  collapsed_nav: boolean;
  /**
   * Publishing a product can have async side effects on the backend like the
   * regeneration of assets, this boolean exists to use to disable certain
   * actions to avoid race condit
   */
  is_publishing_product: boolean;
}

export interface IStore {
  storeState: IStoreState;
  storeDispatch: React.Dispatch<IStoreAction>;
}

type IStoreAction =
  | { type: "SET_STOREFRONT_SLUG"; payload: string }
  | { type: "SET_STOREFRONT_METADATA"; payload: StorefrontMetaData }
  | { type: "SET_API_METADATA"; payload: APIMetaData }
  | { type: "SET_THEME"; payload: DefaultTheme }
  | { type: "SET_STORED_LOCATION"; payload: string | null }
  | { type: "SET_COLLAPSED_NAV"; payload: boolean }
  | { type: "SET_ANALYTICS_ALLOWED"; payload: boolean }
  | { type: "SET_IS_PUBLISHING_PRODUCT"; payload: boolean };

const initialStoreState: IStoreState = {
  //hardcoded slug for the demo, this should be stored correctly to be accessible everywhere
  slug: "",
  storefront_id: "",
  tenant_id: "",
  edition: "starter",
  analytics_allowed: false,
  storefront_metadata: {
    id: "",
    header: "Our Products",
    sub_header: "find the products you are interested in",
    placeholder: "Search By Product Name, formulation, or Formulation ID",
    accepts_payments: false,
    show_privacy_policy_on_contact_forms: false,
    browser_title: "",
    tenant_name: "",
    tenant_id: "",
    tenant_type: null,
    edition: "starter",
    sample_requests_visibility: "hidden",
    quote_requests_visibility: "hidden",
    google_analytics_key: null,
    release_version: "",
    metadata_localization: null,
    enable_invoice_auto_generation: false,
    enable_order_auto_accept: false,
    enable_transaction_lead_quote: false,
    enable_transaction_lead_sample: false,
    enable_vertex_ai_search: false,
    is_contact_message_compulsory: false,
    unlisted_product_requests_enabled: false,
    host: null,
    slug: null,
    theme_object: {
      branding: {
        brandColor: "",
        portalLogoUrl: "",
        publicLogoUrl: "",
        customFontFamily: "",
        favIconUrl: "",
      },
    },
    digital_marketing_enabled: false,
    mandatory_customer_creation_form: false,
    default_currency: "USD",
    default_language: "en",
    use_custom_packaging: true,
    use_custom_privacy_policy: false,
    use_custom_terms_of_sale: false,
    policy_documents: [],
    configured_checkboxes: [
      {
        id: "123",
        name: "I accept terms of service.",
        quote: false,
        sample: true,
        contact: true,
        registration: true,
      },
      {
        id: "12333",
        name: "I accept terms of privacy policy.",
        quote: false,
        sample: false,
        contact: false,
        registration: true,
      },
    ],
    supported_languages: {},
    supported_languages_localized: {},
    email_preferences_enabled: false,
    enable_product_selector: false,
    is_product_selector_enabled: false,
    product_selector_form_inputs_enabled: false,
    product_selector_csv_upload_enabled: false,
    product_selector_csv_document: null,

    default_portfolio_view: "classic",
    route_configuration: {
      portfolio: false,
      contact_us: false,
      registration: false,
      home: false,
    },
    sso_provider: null,
    sso_domain: null,
    sso_only: false,
    custom_support_url: null,
    totp_required: false,
    only_bulk_upload_enabled: false,
    is_chatbot_configured: false,
    enable_email_reminder: false,
  },
  api_metadata: null,
  stored_location: null,
  theme: null,
  collapsed_nav: false,
  is_publishing_product: false,
};

export const Store = createContext<IStore>({
  storeState: initialStoreState,
  // The actual working implementation of this dispatch function is set in the
  // store provider component.
  storeDispatch: () => {},
});

export function reducer(state: IStoreState, action: IStoreAction): IStoreState {
  switch (action.type) {
    case "SET_STOREFRONT_METADATA":
      return {
        ...state,
        storefront_metadata: action.payload,
        // For ease of access, copy some commonly used values up to top level.
        storefront_id: action.payload.id,
        tenant_id: action.payload.tenant_id,
        edition: action.payload.edition,
      };
    case "SET_STOREFRONT_SLUG":
      return { ...state, slug: action.payload };
    case "SET_API_METADATA":
      return { ...state, api_metadata: action.payload };
    case "SET_STORED_LOCATION":
      return { ...state, stored_location: action.payload };
    case "SET_THEME":
      return { ...state, theme: action.payload };
    case "SET_COLLAPSED_NAV":
      return { ...state, collapsed_nav: action.payload };
    case "SET_ANALYTICS_ALLOWED":
      return { ...state, analytics_allowed: action.payload };
    case "SET_IS_PUBLISHING_PRODUCT":
      return { ...state, is_publishing_product: action.payload };
    default:
      return state;
  }
}

interface IStoreProvider {
  initial?: IStoreState;
  children: JSX.Element;
}

export function StoreProvider({
  initial = initialStoreState,
  children,
}: IStoreProvider): JSX.Element {
  const [cookie] = useCookies(["has-accepted-cookie-policy"]);
  // In actual application code this is never called with the initial prop.
  // This only exists to make testing easier, because so many components rely on state from here.
  const [storeState, storeDispatch] = React.useReducer(reducer, initial);

  useEffect(() => {
    if (
      !storeState.analytics_allowed &&
      "has-accepted-cookie-policy" in cookie &&
      cookie["has-accepted-cookie-policy"] === "true" &&
      Boolean(cookie["has-accepted-cookie-policy"]) === true
    ) {
      storeDispatch({
        type: "SET_ANALYTICS_ALLOWED",
        payload: true,
      });
    }
  }, [cookie, storeState.analytics_allowed]);

  return (
    <Store.Provider value={{ storeState, storeDispatch }}>
      {children}
    </Store.Provider>
  );
}
