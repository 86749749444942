import type { InputHTMLAttributes } from "react";
import React, { useContext } from "react";
import type { DeepMap, FieldError } from "react-hook-form";
import styled, { ThemeContext } from "styled-components/macro";
import { showErrors } from "../../util/util-components";
import { useTranslation } from "react-i18next";

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  identifier?: string;
  Icon?: any; // can be string or SVG component
  isValid?: any;
  label: string;
  name: string;
  theref?: any;
  type?: any;
  required: boolean;
  style?: React.CSSProperties;
  rows?: number;
  autoHeight?: boolean;
  formState?: any;
  validate?: any;
  defaultValue?: string;
  disabled?: boolean;
  tabIndex?: number;
  errors?: DeepMap<Record<string, any>, FieldError>;
}

interface IInputProps {
  invalid?: any;
  theme?: any;
}

const Input = styled.textarea.attrs({})`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: auto;
  border: none;
  outline: none;
  resize: none;
`;

const FloatingLabel = styled.span<IInputProps>`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${(props: IInputProps) =>
    props.invalid ? props.theme.errorColor : props.theme.secondaryTextColor};

  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 14px;
  transition: 0.2s ease all;
`;

type WrapperProps = {
  extraSmall?: boolean;
  small?: boolean;
  disabled?: boolean;
  invalid?: any;
  theme?: any;
  autoHeight?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  border-radius: 8px;
  height: ${({ autoHeight }) => (autoHeight ? "auto" : "120px")};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.disabledInput : theme.primaryBG};
  padding: 21px 3px 6px 12px;
  font-size: ${({ theme }) => theme.fontSizes.regular};
  resize: ${({ autoHeight }) => (autoHeight ? "none" : "vertical")};
  overflow: hidden;
  border: ${({ invalid, theme }: IInputProps) =>
    (invalid && `2px solid ${theme.errorColor}`) ||
    `1px solid ${theme.primaryBorder}`};
  /* prettier-ignore */
  ${Input}:focus + ${FloatingLabel},
  ${Input}:not(:placeholder-shown) + ${FloatingLabel},
  ${Input}:read-only + ${FloatingLabel} & {
    outline: none;
    top: 7px;
    bottom: 10px;
    left: 13px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    opacity: 1;
    max-width: ${(props) =>
    props.small ? "176px" : props.extraSmall ? "78px" : "unset"};
  }
  &:focus-within {
    border: ${({ invalid, theme }: IInputProps) =>
      (invalid && `2px solid ${theme.errorColor}`) ||
      `2px solid ${theme.tertiaryBorder}`};
  }
`;

const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IdentifierWrapper = styled.div<IInputProps>`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  position: absolute;
  top: 15px;
  right: 14px;
`;

export const TextArea = ({
  identifier,
  Icon,
  label,
  name,
  theref,
  required,
  formState,
  style,
  autoHeight,
  rows,
  validate,
  defaultValue,
  disabled,
  tabIndex,
  errors,
  onChange,
  value,
}: ITextAreaProps) => {
  const { touched, submitCount, isValid } = formState;
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  if (touched[name]) {
    if (validate) {
      validate(name);
    }
  }
  if (submitCount > 0 && isValid === false && required) {
    return (
      <Wrapper
        disabled={disabled}
        autoHeight={autoHeight}
        invalid={errors && errors[name]?.type}
      >
        <Input
          ref={theref}
          required={required}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChange}
          value={value}
          tabIndex={tabIndex}
          style={style}
          rows={rows}
        />
        <FloatingLabel invalid={errors && errors[name]?.type}>
          {label}
        </FloatingLabel>
        <IdentifierWrapper invalid={errors && errors[name]?.type}>
          {identifier || ""}
        </IdentifierWrapper>
        <IdentifierWrapper>
          {Icon ? <Icon fill={theme.errorColor} /> : null}
        </IdentifierWrapper>
        {errors && showErrors({ errors, name, t })}
      </Wrapper>
    );
  } else {
    return (
      <TextAreaWrapper>
        <Wrapper
          disabled={disabled}
          invalid={errors && errors[name]?.type}
          autoHeight={autoHeight}
        >
          <Input
            placeholder={" "}
            required={required}
            ref={theref}
            name={name}
            defaultValue={defaultValue}
            disabled={disabled}
            tabIndex={tabIndex}
            onChange={onChange}
            value={value}
            style={style}
            rows={rows}
          />
          <FloatingLabel invalid={errors && errors[name]?.type}>
            {label}
          </FloatingLabel>
          <IdentifierWrapper>{identifier || ""}</IdentifierWrapper>
          <IdentifierWrapper>{Icon ? <Icon /> : null}</IdentifierWrapper>
        </Wrapper>
        {errors && showErrors({ errors, name, t })}
      </TextAreaWrapper>
    );
  }
};
