import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { match } from "ts-pattern";
import ReactMarkdown from "react-markdown";

const Message = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f1f1f1;
  border: 0.2px solid ${({ theme }) => theme.primaryBorder};
  max-width: 92%;
  font-size: 13px;
  line-height: 19px;
`;

const UserMessage = styled(Message)`
  align-self: flex-end;
  background-color: ${({ theme }) => theme.navBG};
  border: 0.2px solid ${({ theme }) => theme.brandColor};
  /* #0013a3; */
`;

const AIMessage = styled(Message)`
  align-self: flex-start;
  background-color: white;
  && p:first-child {
    margin-block-start: 0;
    margin-top: 0;
  }
  && p:last-child {
    margin-block-end: 0;
    margin-bottom: 0;
  }
`;

type TextContent = {
  value: string; // Markdown
  // annotations: Annotation[];
};

type Content = {
  type: "text";
  text: TextContent;
};

export type ChatMessage = {
  id: string;
  run_id: string | null;
  role: "assistant" | "user";
  created_at: string | null;
  completed_at: string | null;
  content: Content[];
  status: string | null;
  thread_id: string;
};

type MessageTimelineProps = {
  messages: ChatMessage[];
  contentRef: React.RefObject<HTMLDivElement>;
};

export const MessageTimeline = ({
  messages,
  contentRef,
}: MessageTimelineProps) => {
  const [timelineMessages, setTimelineMessages] = useState<ChatMessage[]>(
    // eslint-disable-next-line tsc/config
    messages?.toReversed()
  );
  useEffect(() => {
    if (messages) {
      // eslint-disable-next-line tsc/config
      setTimelineMessages(messages?.toReversed());
    }
  }, [messages]);

  useEffect(() => {
    if (contentRef?.current && timelineMessages) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [contentRef, timelineMessages]);

  return (
    <>
      {timelineMessages.map((message, index) =>
        match(message.role)
          .with("user", () => (
            <UserMessage key={index}>
              {message.content.map((content, index) => (
                <div key={index}>{content.text.value}</div>
              ))}
            </UserMessage>
          ))
          .with("assistant", () => (
            <AIMessage key={index}>
              {/* If the user refreshes the page at an inopputune time thise may be undefined for the most recent message */}
              <ReactMarkdown
                components={{
                  a: ({ node, ...props }) => (
                    // This will have content at run time.
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a target="_blank" rel="noopener noreferrer" {...props} />
                  ),
                }}
              >
                {message.content[0]?.text?.value}
              </ReactMarkdown>
            </AIMessage>
          ))
          .exhaustive()
      )}
    </>
  );
};
