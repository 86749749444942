import { useTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  CancelButton,
  DestructiveButtonLarge,
  GoBackButtonSmall,
  InvisibleButton,
  PrimaryButtonLarge,
  PrimaryButtonMedium,
} from "../../../../../components/Buttons/Buttons";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import { useRoutePath } from "../../../../../util/Routing";
import { useStoreState } from "../../../../../util/util";
import { TemplatesNav } from "./TemplatesNav";
import type {
  AttributeTemplateSchema,
  TemplateTabItem,
} from "../../../../../types/types.PIM";
import useSWR from "swr";
import type { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import React from "react";
import { TemplateViewCard } from "./TemplateViewCard";
import { ButtonGroup } from "../../../../../components/EditableTitle/EditableTitle";
import { useForm } from "react-hook-form";
import { EditModeTitle } from "../../../../../components/EditableTitle/EditModeTitle";
import { CheckIcon, XIcon } from "../../../../../components/Icons/Icons";
import theme from "../../../../../theme";
import { Flex } from "../../../../../layout/FormLayout";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { Modal } from "../../../../../components/Modal/Modal";
import styled from "styled-components";
import { zodRequiredString } from "../../../../../util/zod.util";
import { useAuthContext } from "../../../../../components/Auth";

const SaveButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 2;
`;

const ModalWrapper = styled.div`
  padding: 50px 90px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const WarningTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.warningTextColor};
  margin-bottom: 30px;
`;

export const TabItemNameSchema = z.object({
  name: z.string(),
});

export type TabItemFormValue = z.infer<typeof TabItemNameSchema>;
export const TabItemNameSchemaFn = (t: TFunction, items: string[]) =>
  z.object({
    name: zodRequiredString(t).refine(
      (val) => Boolean(items.indexOf(val.toLocaleLowerCase()) < 0),
      {
        message: t("Duplicate tab name not allowed"),
      }
    ),
  });

export function SellerAdminTemplateViewTab() {
  const [addNewTab, setAddNewTab] = useState(false);
  const [showSaveButton, setshowSaveButton] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { t } = useTranslation();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const { template_id } = useParams<{ template_id: string }>();
  const location = useLocation();

  const { hasPermission } = useAuthContext();
  const { notifyError, notifySuccess } = useContext(Notifications);

  const { data: template, mutate: mutateTemplate } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(`/v2/tenants/${tenant_id}/pim/templates/${template_id}`);

  const isSKUs = () =>
    template?.collections.find(
      (collection) => collection.name.toLowerCase() === "skus"
    );

  const isSEO = () =>
    template?.groups.find((group) => group.name.toLowerCase() === "seo");

  const [cards, setCards] = useState(template ? template?.layout : []);
  const inputRef = useRef<HTMLInputElement>(null);
  const editNameUseForm = useForm({
    resolver: zodResolver(
      TabItemNameSchemaFn(
        t,
        template?.layout.map((item) => item.display_name.toLocaleLowerCase()) ||
          []
      )
    ),
  });

  const update_priority = <ItemType extends { priority: number }>(
    items: ItemType[]
  ) => {
    return items.map((item, idx) => ({ ...item, priority: idx }));
  };

  const moveRow = ({
    item,
    source,
    data,
  }: {
    item: TemplateTabItem;
    source: string;
    data: TemplateTabItem[];
  }) => {
    const newCards = [...cards];
    const oldSource = newCards.find((card) => card.items.includes(item));
    const oldSourceIndex = oldSource ? newCards.indexOf(oldSource) : -1;
    const newSource = newCards.find((card) => card.name === source);
    const newSourceIndex = newSource ? newCards.indexOf(newSource) : -1;
    if (oldSourceIndex === newSourceIndex) {
      newCards[oldSourceIndex].items = data;
    } else {
      if (oldSource && oldSourceIndex > -1) {
        const items = newCards[oldSourceIndex].items;
        items.splice(oldSource.items.indexOf(item), 1);
        newCards[oldSourceIndex].items = items;
      }
      if (newSource && newSourceIndex > -1) {
        newCards[newSourceIndex].items = data;
      }
    }
    setCards([]);
    setCards(update_priority(newCards));
  };

  const handleAddNewTab = async ({ name }: TabItemFormValue) => {
    setCards((prev) => [
      ...prev,
      {
        id: `new_tab_${cards.length}`,
        name: name,
        display_name: name,
        is_enabled: true,
        tab_type: "custom",
        priority: prev.length,
        items: [],
      },
    ]);
    try {
      await axios.post(`v2/tenants/${tenant_id}/templates/${template_id}/tab`, {
        name,
      });
      await mutateTemplate();
      setAddNewTab(false);
      notifySuccess(t(`Tab (${name}) has been created successfully`));
    } catch (error) {
      await mutateTemplate();
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage ? errorMessage : t("There was an error editing tab name"),
        {
          error,
        }
      );
    }
  };

  const submitTemplateViewData = useCallback(async () => {
    try {
      await axios.put(
        `v2/tenants/${tenant_id}/templates/${template_id}/tab`,
        cards
      );
      setShowConfirmationModal(false);
      setshowSaveButton(false);
      notifySuccess(t("Template view has been updated successfully"));
      await mutateTemplate();
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error editing template view"),
        {
          error,
        }
      );
    }
  }, [
    cards,
    mutateTemplate,
    notifyError,
    notifySuccess,
    t,
    template_id,
    tenant_id,
  ]);

  const saveReorderChanges = () => {
    if (template?.number_of_products && template?.number_of_products > 0) {
      setShowConfirmationModal(true);
    } else {
      submitTemplateViewData();
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        const list = [...cards];
        list.splice(hoverIndex, 0, list.splice(dragIndex, 1)[0]);
        setCards(update_priority(list));
        setshowSaveButton(true);
      }
    },
    [cards]
  );

  useEffect(() => {
    if (template) {
      setCards(template.layout);
    }
  }, [template]);

  return (
    <PageWrapper>
      <div style={{ position: "relative" }}>
        <Link
          to={
            location.search.includes("from=dashboard")
              ? `${adminPath}/dashboard`
              : `${adminPath}/pim/templates`
          }
        >
          <GoBackButtonSmall text={"Back"} />
        </Link>
        {showSaveButton && template && (
          <SaveButtonContainer>
            <DestructiveButtonLarge
              onClick={() => {
                setCards([...template.layout]);
                setshowSaveButton(false);
              }}
              style={{ marginRight: "15px" }}
            >
              {t("Cancel")}
            </DestructiveButtonLarge>
            <PrimaryButtonMedium onClick={() => saveReorderChanges()}>
              {t("Save")}
            </PrimaryButtonMedium>
          </SaveButtonContainer>
        )}
      </div>
      <TemplatesNav
        pageTitle={template?.template_name || t("Template")}
        tabIndex={4}
        templateID={template_id}
      />
      {template && (
        <>
          <ContentWrapper>
            {cards &&
              cards.map((card, i) => (
                <TemplateViewCard
                  key={card.id}
                  disabled={showSaveButton}
                  templateId={template_id}
                  index={i}
                  card={card}
                  moveCard={moveCard}
                  moveRow={moveRow}
                  isSKUs={!!isSKUs()}
                  isSEO={!!isSEO()}
                  mutateTemplate={mutateTemplate}
                  handleDrop={() => setshowSaveButton(true)}
                  tabNames={
                    template?.layout.map((item) =>
                      item.display_name.toLocaleLowerCase()
                    ) || []
                  }
                />
              ))}
            {addNewTab && (
              <Flex style={{ marginBottom: "40px" }}>
                <form
                  id="add_new"
                  noValidate
                  onSubmit={editNameUseForm.handleSubmit(handleAddNewTab)}
                  style={{ position: "relative" }}
                >
                  <EditModeTitle
                    name={"name"}
                    theref={inputRef}
                    register={editNameUseForm.register}
                    formState={editNameUseForm.formState}
                    errors={editNameUseForm.errors}
                    tabIndex={0}
                    defaultValue={""}
                    fontSize={"regular"}
                    fontWeight={"large"}
                  />
                </form>
                <ButtonGroup>
                  <InvisibleButton form={"add_new"} type="submit">
                    <CheckIcon fill={theme.activeToggleBG} />
                  </InvisibleButton>
                  <InvisibleButton
                    type="button"
                    onClick={() => setAddNewTab(false)}
                  >
                    <XIcon fill={theme.destructiveTextColor} />
                  </InvisibleButton>
                </ButtonGroup>
              </Flex>
            )}
            {!addNewTab &&
              template?.layout &&
              template?.layout.length > 0 &&
              hasPermission("modify_templates") && (
                <PrimaryButtonLarge
                  onClick={() => setAddNewTab(true)}
                  style={{ marginBottom: "30px" }}
                >
                  {t("Add Tab")}
                </PrimaryButtonLarge>
              )}
          </ContentWrapper>

          <Modal
            overlay
            show={showConfirmationModal}
            closeModal={() => {
              setShowConfirmationModal(false);
              setCards([...template?.layout]);
            }}
            modalWidth={"680px"}
          >
            <ModalWrapper>
              <WarningTitle>
                {t(
                  `{{numberOfProducts}} {{product}} will get affected by this change, Are you sure you want to confirm changes?`,
                  {
                    numberOfProducts: template?.number_of_products,
                    product:
                      template?.number_of_products === 1
                        ? "product"
                        : "products",
                  }
                )}
              </WarningTitle>
              <PrimaryButtonMedium
                onClick={() => submitTemplateViewData()}
                style={{ marginTop: "30px" }}
              >
                {t("Proceed")}
              </PrimaryButtonMedium>
              <br />
              <CancelButton
                onClick={() => {
                  setShowConfirmationModal(false);
                  setCards([...template?.layout]);
                }}
                style={{ padding: "20px 30px" }}
              >
                {t("Cancel")}
              </CancelButton>
            </ModalWrapper>
          </Modal>
        </>
      )}
    </PageWrapper>
  );
}
