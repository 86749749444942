import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components/macro";
import useSWR from "swr";
import {
  GoBackButton,
  PrimaryButtonSmall,
  TextButton,
} from "../../../../../components/Buttons/Buttons";
import { ConfirmDialog } from "../../../../../components/ConfirmDialog/ConfirmDialog";
import { SectionTitle } from "../../../../../components/Form/Form";
import {
  ArchiveIcon,
  EditIcon,
  SystemDefaultIcon,
} from "../../../../../components/Icons/Icons";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import {
  H6,
  SoftHeader,
  SoftHeader2,
} from "../../../../../components/Typography/Typography";
import { endpoints } from "../../../../../endpoints";
import type {
  AttributeSchema,
  PortfolioControlsSchema,
  ShortListSchema,
} from "../../../../../types/types.PIM";
import {
  removeUnderscore,
  toTitleCase,
  useHasMultipleLanguages,
  useStoreState,
} from "../../../../../util/util";
import { EditAttribute } from "./EditAttribute";
import type { AxiosError } from "axios";
import Axios from "axios";
import { useRoutePath } from "../../../../../util/Routing";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { AttributesNav } from "../components/AttributesNav";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import ReactTooltip from "react-tooltip";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { DeletableChips } from "../../../../../components/DeletableChips/DeletableChips";
import noop from "lodash/noop";
import { useAuthContext } from "../../../../../components/Auth";
import { Flex } from "../../../../../layout/FormLayout";
import { SystemIconWrapper } from "../components/PIM.components.util";
import { AttributeTranslations } from "./AttributeTranslations";

const AttributeDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AttributeItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AttributeDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0;
  padding: 0;
  max-width: 300px;
  hyphens: auto;
`;

const DeleteTextContainer = styled.div`
  color: ${({ theme }) => theme.errorColor};
  display: flex;
  gap: 4px;
  padding: 2px;
  align-items: center;
`;

const EditButtonContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px 16px;
`;

const ButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: fit-content;
`;

const hasList = (inputType: string) =>
  inputType === "single_select" || inputType === "multi_select";

export const AttributeDetailPage = () => {
  const [showEditSlideOut, setEditSlideOut] = useState(false);
  const [isPortfolioControl, setIsPortfolioControl] = useState<boolean>(false);
  const [showArchiveDialogBox, setArchiveDialogBox] = useState(false);
  const { id: attribute_id } = useParams<{ id: string }>();
  const { tenant_id } = useStoreState();
  const { adminPath } = useRoutePath();
  const {
    data: attribute,
    mutate,
    error: attributeError,
  } = useSWR<AttributeSchema>([
    endpoints.v2_tenants_id_pim_attributes_id(tenant_id, attribute_id),
    useMemo(() => ({ params: { include_translations: true } }), []),
  ]);

  const { data: list } = useSWR<ShortListSchema>(
    !!attribute && hasList(attribute.input_type)
      ? endpoints.v2_tenants_id_or_slug_pim_lists_id(
          tenant_id,
          attribute.list_id
        )
      : null,
    { revalidateOnFocus: false }
  );

  const { t } = useTranslation();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const theme = useContext(ThemeContext);
  const { storefront_id } = useStoreState();
  const history = useHistory();
  const location = useLocation();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });
  const closeEditSlideOut = () => setEditSlideOut(false);
  const closeArchiveDialogBox = () => setArchiveDialogBox(false);
  const { hasPermission } = useAuthContext();
  const hasMultipleLanguages = useHasMultipleLanguages();

  useSWR<{ data: PortfolioControlsSchema[] }>(
    `v2/storefronts/${storefront_id}/portfolio-controls`,
    {
      onSuccess: ({ data: controlsData }) => {
        setIsPortfolioControl(
          controlsData
            .map((control) => control.attribute.new_object_type)
            .indexOf(attribute?.new_object_type || "") > -1 ?? false
        );
      },
      revalidateOnFocus: true,
    }
  );

  const archiveAttribute = async () => {
    try {
      await Axios.delete(
        endpoints.v2_tenants_id_pim_attributes_id(tenant_id!, attribute_id)
      );
      notifySuccess(t("Attribute archived successfully"));
      history.push(`${adminPath}/pim/attributes`);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("Could not archive attribute. Something went wrong."),
        {
          error,
        }
      );
    }
  };

  const isLoadingAttributeDetail = !attribute && !attributeError;

  if (attributeError) {
    return (
      <ErrorPlaceholder
        message={t("There was an error loading the attribute")}
      />
    );
  }

  if (isLoadingAttributeDetail) {
    return <DelayedSpinner />;
  }

  const params = new URLSearchParams();

  if (query.q) {
    params.append("q", query.q);
  }

  params.append("offset", String(query?.offset ?? 0));
  params.append("perPage", String(query?.perPage ?? 10));

  return (
    <PageWrapper>
      <AttributesNav tabName="Attributes" params={params} />
      {attribute && (
        <AttributeDetailContainer>
          <div
            style={{
              marginBottom: "19px",
              marginLeft: "-20px",
              width: "fit-content",
            }}
          >
            <Link
              to={
                location.search.includes("from=dashboard")
                  ? `${adminPath}/dashboard`
                  : `${adminPath}/pim/attributes?${params}`
              }
            >
              <GoBackButton text={t("Attributes")} />
            </Link>
          </div>
          <Flex
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SectionTitle style={{ marginTop: "20px" }}>
              {t("Attribute Definition")}
            </SectionTitle>
            {attribute.is_system && (
              <Flex
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <SystemIconWrapper
                  data-for={`system${attribute.id}`}
                  data-tip={t("System Default")}
                >
                  <SystemDefaultIcon width={22} height={22} />
                </SystemIconWrapper>
                <SoftHeader2>{t("System default")}</SoftHeader2>
              </Flex>
            )}
          </Flex>
          <AttributeItem>
            <SoftHeader>{t("Attribute Name")}</SoftHeader>
            <H6>{attribute.name}</H6>
          </AttributeItem>
          <AttributeItem>
            <SoftHeader>{t("Display Name")}</SoftHeader>
            <H6>
              {attribute.display_name ? t([attribute.display_name]) : "--"}
            </H6>
          </AttributeItem>
          <AttributeItem>
            <SoftHeader>{t("Short description")}</SoftHeader>
            <AttributeDescription>
              {attribute.description ? t([attribute.description]) : "--"}
            </AttributeDescription>
          </AttributeItem>
          <SectionTitle>{t("Attribute Type")}</SectionTitle>
          <AttributeItem>
            <SoftHeader>{t("Attribute Type")}</SoftHeader>
            <H6>{toTitleCase(removeUnderscore(attribute.input_type))}</H6>
          </AttributeItem>
          {hasList(attribute.input_type) && (
            <AttributeItem>
              <SoftHeader>{t("Chosen List")}</SoftHeader>
              <H6>{list?.parent ? list?.parent?.name : list?.name ?? ""}</H6>
              {!!list?.parent && (
                <SoftHeader style={{ marginTop: "4px" }}>
                  {t("Selected values from list")}
                </SoftHeader>
              )}
            </AttributeItem>
          )}
          {!!attribute.choices &&
            attribute.choices.length > 0 &&
            !!list?.parent && (
              <DeletableChips
                chips={
                  Array.from(attribute.choices)?.map?.((choice) => ({
                    name: choice,
                    active: false,
                  })) ?? []
                }
                noneSelectedName="choices"
                handleClick={noop}
              />
            )}
          <ButtonsContainer>
            {hasPermission("delete_attributes") && (
              <TextButton
                disabled={attribute.is_system || isPortfolioControl}
                onClick={() => setArchiveDialogBox(true)}
              >
                <DeleteTextContainer
                  data-for="attribute-archive-delete-buttons"
                  data-tip={
                    attribute.is_system
                      ? t("You cannot archive a system default attribute.")
                      : isPortfolioControl && !attribute.is_system
                      ? t("You cannot archive a portfolio control attribute.")
                      : ""
                  }
                >
                  <ArchiveIcon fill={theme.errorColor} />
                  {t("Archive")}
                </DeleteTextContainer>
              </TextButton>
            )}
            {hasPermission("modify_attributes") && (
              <PrimaryButtonSmall
                style={{ padding: 0 }}
                onClick={() => setEditSlideOut(true)}
              >
                <EditButtonContainer>
                  <EditIcon />
                  {t("Edit")}
                </EditButtonContainer>
              </PrimaryButtonSmall>
            )}
            <ReactTooltip id="attribute-archive-delete-buttons" />
          </ButtonsContainer>
          {hasMultipleLanguages ? (
            <div style={{ marginTop: "40px" }}>
              <AttributeTranslations attribute={attribute} mutate={mutate} />
            </div>
          ) : (
            <></>
          )}
          <SlideOut closeFlyout={closeEditSlideOut} show={showEditSlideOut}>
            <EditAttribute
              attribute={attribute}
              onComplete={closeEditSlideOut}
              refreshAttribute={mutate}
              list={list}
            />
          </SlideOut>
          <ConfirmDialog
            show={showArchiveDialogBox}
            confirmMessage={t(
              "Are you sure you want to archive this attribute?"
            )}
            closeDialog={closeArchiveDialogBox}
            handleConfirm={archiveAttribute}
          />
        </AttributeDetailContainer>
      )}
    </PageWrapper>
  );
};
